// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EkhoDealerFooter, Card } from '@ekhodealer/ekho-common/components';

import { CHECKOUT_INFO_MAX_WIDTH } from '@ekhodealer/ekho-common';
import StackedContext from '../../contexts/stackedContext';
import { faqOnClick, privacyOnClick, termsOnClick } from '../../Utils/util_funcs';

const PurchasePreviewContainer = ({ children }) => {
	const { stacked } = useContext(StackedContext);
	return (
		<Card
			shadow='none'
			style={{
				height: stacked ? `calc(var(--purchase-preview-percent-height) - 100px)` : '100vh',
				marginBottom: '0vh',
				position: stacked ? 'relative' : 'absolute',
				right: stacked ? '' : '0',
				borderRadius: '0rem',
				paddingRight: stacked ? '0' : '5rem',
				paddingLeft: stacked ? '0rem' : '2rem',
				maxWidth: stacked ? '100%' : `${CHECKOUT_INFO_MAX_WIDTH}px`,
				width: '100%',
				paddingTop: stacked ? '0' : '3rem',
				paddingBottom: stacked ? '0' : '3rem',
			}}>
			{children}
			{!stacked && (
				<EkhoDealerFooter
					linkOneOnClick={() => {
						faqOnClick(process.env.REACT_APP_OEM_KEY);
					}}
					linkTwoOnClick={() => {
						privacyOnClick(process.env.REACT_APP_OEM_KEY);
					}}
					linkThreeOnClick={() => {
						termsOnClick(process.env.REACT_APP_OEM_KEY);
					}}
					linkFourText=''
					stacked={false}
				/>
			)}
		</Card>
	);
};

PurchasePreviewContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default PurchasePreviewContainer;
