// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from '@ekhodealer/ekho-common/components';
import {
	CHECKOUT_INFO_MAX_WIDTH,
	WEB_INNER_SIDE_PADDING,
	WEB_OUTER_SIDE_PADDING,
} from '@ekhodealer/ekho-common';
import StackedContext from '../../contexts/stackedContext';
import { SCROLL_CONTAINER_ID } from '../../common/data/constants';

const CheckoutInfoContainer = ({ children }) => {
	const { stacked } = useContext(StackedContext);
	return (
		<Card
			// In stacked case, don't show any shadow

			shadow={stacked ? 'none' : ''}
			style={{
				height: stacked ? '' : '100%',
				borderRadius: 0,
				marginBottom: 0,
				padding: 0,
				boxShadow: '-10px 0 20px -5px rgba(0, 0, 0, 0.08)',
			}}>
			<CardBody
				className={stacked ? '' : SCROLL_CONTAINER_ID}
				style={{
					scrollBehavior: stacked ? 'auto' : 'smooth', // Adjusts scroll behavior based on stacked
					maxHeight: stacked ? 'none' : '100%', // Removes max height restriction in stacked
					overflowY: stacked ? 'visible' : 'auto', // Prevents scrolling in stacked by changing overflow
					padding: stacked ? '0.5rem 2rem' : '3rem 0',
				}}>
				{!stacked ? (
					<div
						style={{
							maxWidth: `${CHECKOUT_INFO_MAX_WIDTH}px`,
							paddingLeft: `${WEB_INNER_SIDE_PADDING}rem`,
							paddingRight: `${WEB_OUTER_SIDE_PADDING}rem`,
						}}>
						{children}
					</div>
				) : (
					children
				)}
			</CardBody>
		</Card>
	);
};

CheckoutInfoContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default CheckoutInfoContainer;
