// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { RegisteredUserContext, OemProfileContext } from '@ekhodealer/ekho-common/components';
import CurrentPaymentIntentContext from './currentPaymentIntentContext';
import AccessoryCountsContext from './accessoryCountContext';
import CheckoutCartContext from './checkoutCartContext';
import UniqueAccessoriesContext from './uniqueAccessoriesContext';
import VehiclesContext from './vehiclesContext';
import CartHasBoltOns from './cartHasBoltOnsContext';
import OemVehiclesContext from './oemVehiclesContext';
import StackedContext from './stackedContext';
import OemPickUpLocationsContext from './oemPickUpLocationsContext';
import CartCostsContext from './cartCostsContext';
import SelectedPaymentOptionContext from './selectedPaymentOptionContext';
import SelectedDeliveryOptionContext from './selectedDeliveryOptionContext';
import SelectedRegistrationOptionContext from './selectedRegistrationOptionContext';
import PaymentIntentsContext from './paymentIntentsContext';
import StateDataContext from './stateDataContext';
import ApplicableDiscountsContext from './applicableDiscountsContext';
import OrderIsBeingPlacedContext from './orderIsBeingPlacedContext';
import RegistrationAddressContext from './registrationAddresContext';
import GoogleMapsApiKeyContext from './googleMapsApiKeyContext';
import AbandonedCartIdContext from './abandonedCartIdContext';
import SelectedVariantOptionsContext from './SelectedVariantOptionsContext';
import AccountDetailsContext from './accountsDetailsContext';
import CurrentVehicleConfigContext from './currentVehicleContext';
import VehicleConfigContext from './vehicleConfigContext';
import FetchCheckoutContext from './fetchCheckoutContext';
import ActiveModuleContext from './activeModuleContext';
import DeliveryTimeContext from './deliveryTimeContext';
import RelevantFinancingProgramsContext from './relevantFinancingProgramsContext';
import ShippingAddressSameAsRegistrationAddressContext from './shippingAddressSameAsRegistrationAddressContext';

const ContextWrapper = ({
	registeredUserValue,
	oemProfileValue,
	currentPaymentIntentValue,
	vehiclesValue,
	uniqueAccessoriesValue,
	accessoryCountsValue,
	checkoutCartValue,
	children,
	cartHasBoltOns,
	oemVehiclesValue,
	registrationAddressValue,
	stackedValue,
	oemPickUpLocationsValue,
	cartCostsValue,
	selectedPaymentOptionValue,
	selectedDeliveryOptionValue,
	selectedRegistrationOptionValue,
	stateDataValue,
	applicableDiscountsValue,
	orderIsBeingPlacedValue,
	abandonedCartIdValue,
	googleMapsApiKeyValue,
	selectedVariantOptionsValue,
	accountDetailsValue,
	currentVehicleConfigValue,
	vehicleConfigValue,
	fetchCheckoutValue,
	activeModuleValue,
	deliveryTimeValue,
	relevantFinancingProgramsValue,
	shippingAddressSameAsRegistrationAddressValue,
}) => (
	<RegisteredUserContext.Provider value={registeredUserValue}>
		<OemProfileContext.Provider value={oemProfileValue}>
			<CurrentPaymentIntentContext.Provider value={currentPaymentIntentValue}>
				<VehiclesContext.Provider value={vehiclesValue}>
					<SelectedVariantOptionsContext.Provider value={selectedVariantOptionsValue}>
						<UniqueAccessoriesContext.Provider value={uniqueAccessoriesValue}>
							<AccessoryCountsContext.Provider value={accessoryCountsValue}>
								<CheckoutCartContext.Provider value={checkoutCartValue}>
									<CartHasBoltOns.Provider value={cartHasBoltOns}>
										<OemVehiclesContext.Provider value={oemVehiclesValue}>
											<StackedContext.Provider value={stackedValue}>
												<OemPickUpLocationsContext.Provider
													value={oemPickUpLocationsValue}>
													<CartCostsContext.Provider
														value={cartCostsValue}>
														<SelectedPaymentOptionContext.Provider
															value={selectedPaymentOptionValue}>
															<SelectedDeliveryOptionContext.Provider
																value={selectedDeliveryOptionValue}>
																<SelectedRegistrationOptionContext.Provider
																	value={
																		selectedRegistrationOptionValue
																	}>
																	<StateDataContext.Provider
																		value={stateDataValue}>
																		<PaymentIntentsContext.Provider
																			value={
																				currentPaymentIntentValue
																			}>
																			<ApplicableDiscountsContext.Provider
																				value={
																					applicableDiscountsValue
																				}>
																				<OrderIsBeingPlacedContext.Provider
																					value={
																						orderIsBeingPlacedValue
																					}>
																					<RegistrationAddressContext.Provider
																						value={
																							registrationAddressValue
																						}>
																						<AbandonedCartIdContext.Provider
																							value={
																								abandonedCartIdValue
																							}>
																							<GoogleMapsApiKeyContext.Provider
																								value={
																									googleMapsApiKeyValue
																								}>
																								<AccountDetailsContext.Provider
																									value={
																										accountDetailsValue
																									}>
																									<VehicleConfigContext.Provider
																										value={
																											vehicleConfigValue
																										}>
																										<CurrentVehicleConfigContext.Provider
																											value={
																												currentVehicleConfigValue
																											}>
																											<FetchCheckoutContext.Provider
																												value={
																													fetchCheckoutValue
																												}>
																												<ActiveModuleContext.Provider
																													value={
																														activeModuleValue
																													}>
																													<DeliveryTimeContext.Provider
																														value={
																															deliveryTimeValue
																														}>
																														<RelevantFinancingProgramsContext.Provider
																															value={
																																relevantFinancingProgramsValue
																															}>
																															<ShippingAddressSameAsRegistrationAddressContext.Provider
																																value={
																																	shippingAddressSameAsRegistrationAddressValue
																																}>
																																{
																																	children
																																}
																															</ShippingAddressSameAsRegistrationAddressContext.Provider>
																														</RelevantFinancingProgramsContext.Provider>
																													</DeliveryTimeContext.Provider>
																												</ActiveModuleContext.Provider>
																											</FetchCheckoutContext.Provider>
																										</CurrentVehicleConfigContext.Provider>
																									</VehicleConfigContext.Provider>
																								</AccountDetailsContext.Provider>
																							</GoogleMapsApiKeyContext.Provider>
																						</AbandonedCartIdContext.Provider>
																					</RegistrationAddressContext.Provider>
																				</OrderIsBeingPlacedContext.Provider>
																			</ApplicableDiscountsContext.Provider>
																		</PaymentIntentsContext.Provider>
																	</StateDataContext.Provider>
																</SelectedRegistrationOptionContext.Provider>
															</SelectedDeliveryOptionContext.Provider>
														</SelectedPaymentOptionContext.Provider>
													</CartCostsContext.Provider>
												</OemPickUpLocationsContext.Provider>
											</StackedContext.Provider>
										</OemVehiclesContext.Provider>
									</CartHasBoltOns.Provider>
								</CheckoutCartContext.Provider>
							</AccessoryCountsContext.Provider>
						</UniqueAccessoriesContext.Provider>
					</SelectedVariantOptionsContext.Provider>
				</VehiclesContext.Provider>
			</CurrentPaymentIntentContext.Provider>
		</OemProfileContext.Provider>
	</RegisteredUserContext.Provider>
);
export default ContextWrapper;
